td {
  padding: 3px;
  text-align: center;
  font-size: 80%;
}

thead td {
  font-size: 100%;
}

table {
}

h3 {
}

h1,
h2,
h3 {
  text-transform: uppercase;
}

h2 {
  margin-bottom: 25px;
}
