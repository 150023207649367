.logo {
  height: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.logo img {
  width: 200px;
}
